import { Link, useNavigate } from "react-router-dom"
import { MdArrowBackIos, MdMenu } from 'react-icons/md'

import styled from "styled-components"
import { useState } from "react"

const Header = styled.header`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.25rem 0;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 500;
`

const BtnVoltar = styled.div`
    position: absolute;
    left: 1rem;
    z-index: 100;
`

const BtnMenu = styled.div`
    position: absolute;
    right: 1rem;
    z-index: 100;
`

const Titulo = styled.div`
    font-size: 0.85rem;
`

export default function TopoMenu(props) {
    const navegar = useNavigate()

    function navegacao() {
        navegar(props.voltar, {
            state: props.state
        })
    }

    return (
        <Header id="header">
            <BtnVoltar>
                <MdArrowBackIos
                    size={28}
                    className="float-right"
                    onClick={() => { navegacao() }}
                />
            </BtnVoltar>
            <Titulo>{props.titulo}</Titulo>
            {props.setShow ? (
                <BtnMenu>
                    <MdMenu
                        size={28}
                        className="float-right"
                        onClick={() => { props.setShow(true) }}
                    />
                </BtnMenu>
            ) : ('')}
        </Header>
    )
}